import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducers";

const localStorageKey = "myReduxState";
const preloadedState = loadStateFromLocalStorage();

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),
});

store.subscribe(() => {
  saveStateToLocalStorage(store.getState());
});

function loadStateFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(localStorageKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.warn("Failed to load state from LocalStorage", err);
    return undefined;
  }
}

function saveStateToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(localStorageKey, serializedState);
  } catch (err) {
    console.warn("Failed to save state to LocalStorage", err);
  }
}
