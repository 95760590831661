import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TopBar from "../components/layout/topbar";

const PrivateRoute = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  if (!isLoggedIn || (user && user.role != "ADMIN")) {
    return <Navigate to="/login" />;
  }

  return (
    <Fragment className="container">
      <ToastContainer />
      <TopBar />
      <div className="p-4" style={{ overflowX: "auto" }}>
        <Outlet />
      </div>
    </Fragment>
  );
};

export default PrivateRoute;
