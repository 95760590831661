import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import SwaggerContext from "../../SwaggerContext";
import {
  notifyCustomSuccess,
  notifyError,
  notifySuccess,
} from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomToastWithLink from "../../components/ToastWithLink";
import { DefaultEditor } from "react-simple-wysiwyg";

const initialState = {
  pagination: {
    page: 0,
    limit: 100,
  },
  category: {
    name: "",
    group: "",
    description: "",
  },
};

const PageForm = () => {
  const { id } = useParams();
  const { categoryApi } = useContext(SwaggerContext);
  const [data, setData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const localtion = useLocation();
  const [showModal, setShowModal] = useState(false);

  const loadCategory = (id) => {
    categoryApi.getCategoryById(id, (error, data, response) => {
      if (error || response.statusCode != 200) {
        if (response.statusCode == 404) {
          notifyError(`No se ha encontrado la categoría. ${error}`);
          navigate(`/categorias`);
          return;
        }
        notifyError(`Ha habido un error al cargar la categoría: ${error}`);
        navigate(`/categorias`);
        return;
      }
      setData(data);
    });
  };

  useEffect(() => {
    if (currentPath.includes("nueva")) {
      setData(initialState.category);
    } else if (id) {
      loadCategory(id);
    }
  }, [localtion]);

  const handleClearForm = () => {
    setData(initialState.category);
  };

  const handleChangeCategoryForm = (e) => {
    let target = e.target;
    let value = target.value;
    setData((previousData) => {
      return { ...previousData, [target.id]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = {};
    if (!data.name) errors.name = true;
    if (!data.group) errors.group = true;
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      notifyError("Falta alguno de los campos obligatorios.");
      return;
    }
    setErrors({});

    if (data.id) {
      saveCategory();
      return;
    }

    createCategory();
  };

  const saveCategory = () => {
    let categoryDto = {
      id: data.id,
      name: data.name,
      group: parseInt(data.group),
      description: data.description
    };

    categoryApi.updateCategoryById(
      data.id,
      categoryDto,
      (error, data, response) => {
        if (error || response.statusCode != 200) {
          notifyError(
            `Ha habido un error al actualizar la categoría: ${error}`
          );
          return;
        }
        notifySuccess("Categoría actualizada correctamente.");
      }
    );
    return;
  };

  const createCategory = () => {
    let categoryInputDto = {
      name: data.name,
      group: parseInt(data.group),
      description: data.description
    };

    categoryApi.createCategory(categoryInputDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al crear la categía: ${error}`);
        return;
      }
      handleClearForm();
      notifyCustomSuccess(
        <CustomToastWithLink
          text="Categoría guardada correctamente. Click para ver ahora."
          path={`/categorias/editar/${data.id}`}
        />
      );
    });
  };

  const handleDelete = () => {
    categoryApi.deleteCategoryById(data.id, (error, data, response) => {
      if (error || response.statusCode != 204) {
        if (response.body.error === "ConstraintViolationException") {
          notifyError(
            `Esta categoría tiene páginas asociadas, elimínalas primero.`
          );
          return;
        }
        notifyError(`Error al elminar la categía: ${error}`);
        return;
      }
      notifySuccess("Categoría eliminada correctamente");
      navigate(`/categorias`);
    });
  };

  if (null === data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <Form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
      <Row>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              isInvalid={errors.name}
              type="text"
              placeholder=""
              value={data.name}
              onChange={(e) => handleChangeCategoryForm(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="group">
            <Form.Label>
              Tipo<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              isInvalid={errors.group}
              aria-label="Default select example"
              value={data.group}
              name="gorup"
              onChange={(e) => handleChangeCategoryForm(e)}
            >
              <option>Establecer tipo</option>
              <option value="1">Grupo 1</option>
              <option value="2">Grupo 2</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Form.Group controlId="bodyForm">
            <Form.Label>Descripción</Form.Label>
            <DefaultEditor
              autoCorrect={false}
              value={data.description}
              onChange={(e) => {
                setFormChanged(true)
                e.target.id = "description";
                handleChangeCategoryForm(e);
              }}
            />
          </Form.Group>
        </Col>
      </Row>
      {id ? (
        <Row className="d-flex justify-content-end  mt-4">
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="danger"
              type="button"
              onClick={() => setShowModal(true)}
            >
              Eliminar
            </Button>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className="mr-2"
              disabled={!formChanged}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="d-flex justify-content-end  mt-4">
          <Col xs="auto" className="d-flex justify-content-en">
            <Button
              className="text-white"
              variant="warning"
              type="button"
              onClick={handleClearForm}
            >
              Limpiar
            </Button>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className="mr-2"
              disabled={!formChanged}
            >
              Crear
            </Button>
          </Col>
        </Row>
      )}
      <ConfirmationModal
        show={showModal}
        setShowModal={setShowModal}
        deleteFunction={handleDelete}
        deleteArguments={[]}
      />
    </Form>
  );
};

export default PageForm;
