import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import List from "./List";
import Form from "./Form";

const Page = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const routes = {
    "/paginas": (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Listado de Páginas
        </h1>
        <List />
      </Fragment>
    ),
    "/paginas/nueva": (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Nueva Página
        </h1>
        <Form />
      </Fragment>
    ),
    [`/paginas/editar/${id}`]: (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Editar Página
        </h1>
        <Form />
      </Fragment>
    ),
  };

  return routes[pathname];
};

export default Page;
