import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import List from "./List";
import Form from "./Form";

const Category = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const routes = {
    "/categorias": (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Listado de Categorías
        </h1>
        <List />
      </Fragment>
    ),
    "/categorias/nueva": (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Nueva Categoría
        </h1>
        <Form />
      </Fragment>
    ),
    [`/categorias/editar/${id}`]: (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Editar Categoría
        </h1>
        <Form />
      </Fragment>
    ),
  };

  return routes[pathname];
};

export default Category;
