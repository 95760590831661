import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  notifyCustomSuccess,
  notifyError,
  notifySuccess,
} from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomToastWithLink from "../../components/ToastWithLink";
import SwaggerContext from "../../SwaggerContext";

const initialState = {
  pagination: {
    page: 0,
    limit: 100,
  },
  user: {
    name: "",
    surname: "",
    username: "",
    email: "",
    password: "",
    repeat_password: "",
    role: "",
    role_id: "",
  },
};

const PageForm = () => {
  const { id } = useParams();
  const { userApi, roleApi } = useContext(SwaggerContext);
  const [data, setData] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const localtion = useLocation();
  const [roles, setRoles] = useState();
  const [showModal, setShowModal] = useState(false);

  const loadUser = (id) => {
    userApi.getUserById(id, (error, data, response) => {
      if (error || response.statusCode != 200) {
        if (response.statusCode == 404) {
          notifyError(`No se ha encontrado el usuario. ${error}`);
          navigate(`/usuarios`);
          return;
        }
        notifyError(`Ha habido un error al cargar el usuario: ${error}`);
        navigate(`/usuarios`);
        return;
      }
      data.password = "";
      setData(data);
    });
  };

  const loadRoles = (opts) => {
    roleApi.getRoles(opts, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Error al cargar los roles: ${error}`);
        navigate(`/usuarios`);
        return;
      }
      setRoles(data.roles);
    });
  };

  useEffect(() => {
    let opts = initialState.pagination;
    loadRoles(opts);
    if (currentPath.includes("nuevo")) {
      setData(initialState.user);
    } else if (id) {
      loadUser(id);
    }
  }, [localtion]);

  const handleClearForm = () => {
    setData(initialState.user);
  };

  const handleChangeUserForm = (e) => {
    let target = e.target;
    let value = target.value;
    setData((previousData) => {
      return { ...previousData, [target.id]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = {};
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*.])"
    );
    if (!data.name) errors.name = true;
    if (!data.username) errors.username = true;
    if (!data.surname) errors.surname = true;
    if (!data.email) errors.email = true;
    if (!data.role_id) errors.role_id = true;
    if (data.password && !regex.test(data.password)) errors.password = true;
    if (data.password && data.password != data.repeat_password)
      errors.repeat_password = true;
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      notifyError("Falta alguno de los campos obligatorios.");
      return;
    }
    setErrors({});

    if (data.id) {
      saveUser();
      return;
    }

    createUser();
  };

  const saveUser = () => {
    let userDto = {
      id: data.id,
      name: data.name,
      surname: data.surname,
      username: data.username,
      role_id: data.role_id,
      email: data.email,
      password: data.password,
    };

    userApi.updateUserById(data.id, userDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al actualizar el usuario: ${error}`);
        return;
      }
      notifySuccess("Usuario actualizado correctamente.");
    });
    return;
  };

  const createUser = () => {
    let userInputDto = {
      id: data.id,
      name: data.name,
      surname: data.surname,
      username: data.username,
      role_id: data.role_id,
      email: data.email,
      password: data.password,
    };

    userApi.createUser(userInputDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al crear el usuario: ${error}`);
        return;
      }
      handleClearForm();
      notifyCustomSuccess(
        <CustomToastWithLink
          text="Usuario guardado correctamente. Click para ver ahora."
          path={`/usuarios/editar/${data.id}`}
        />
      );
    });
  };

  const handleDelete = () => {
    userApi.deleteUserById(data.id, (error, data, response) => {
      if (error || response.statusCode != 204) {
        notifyError(`Error al eliminar el usuario: ${error}`);
        return;
      }
      notifySuccess("Usuario eliminado correctamente");
      navigate(`/usuarios`);
    });
  };

  if (null === data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <Form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
      <Row>
        <Col>
          <Form.Group controlId="name">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              isInvalid={errors.name}
              type="text"
              placeholder=""
              value={data.name}
              onChange={(e) => handleChangeUserForm(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="surname">
            <Form.Label>Apellidos</Form.Label>
            <Form.Control
              isInvalid={errors.surname}
              type="text"
              placeholder=""
              value={data.surname}
              onChange={(e) => handleChangeUserForm(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="username">
            <Form.Label>Nombre de Usuario</Form.Label>
            <Form.Control
              isInvalid={errors.username}
              type="text"
              placeholder=""
              value={data.username}
              onChange={(e) => handleChangeUserForm(e)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              isInvalid={errors.email}
              type="text"
              placeholder=""
              value={data.email}
              onChange={(e) => handleChangeUserForm(e)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="password">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              isInvalid={errors.password}
              type="password"
              placeholder=""
              value={data.password}
              onChange={(e) => handleChangeUserForm(e)}
            />
            <Form.Control.Feedback type="invalid">
              La contraseña debe tener al menos 8 caracteres y contiene al menos
              una letra mayúscula, una letra minúscula, un número y uno de estos
              símbolos(!@#$%^&*.).
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="repeat_password">
            <Form.Label>Repetir Contraseña</Form.Label>
            <Form.Control
              isInvalid={errors.repeat_password}
              type="password"
              placeholder=""
              value={data.repeat_password}
              onChange={(e) => handleChangeUserForm(e)}
            />
            <Form.Control.Feedback type="invalid">
              Las contraseñas deben coincidir
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Group controlId="role_id">
            <Form.Label>
              Rol<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select
              isInvalid={errors.role_id}
              aria-label="Default select example"
              value={data.role_id}
              name="role"
              onChange={(e) => handleChangeUserForm(e)}
            >
              <option>Establecer rol</option>
              {roles
                ? roles.map((category, i) => {
                    return (
                      <option key={i} value={category.id}>
                        {category.name}
                      </option>
                    );
                  })
                : null}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      {id ? (
        <Row className="d-flex justify-content-end  mt-4">
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="danger"
              type="button"
              onClick={() => setShowModal(true)}
            >
              Eliminar
            </Button>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className="mr-2"
              disabled={!formChanged}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      ) : (
        <Row className="d-flex justify-content-end  mt-4">
          <Col xs="auto" className="d-flex justify-content-en">
            <Button
              className="text-white"
              variant="warning"
              type="button"
              onClick={handleClearForm}
            >
              Limpiar
            </Button>
          </Col>
          <Col xs="auto" className="d-flex justify-content-end">
            <Button
              variant="primary"
              type="submit"
              className="mr-2"
              disabled={!formChanged}
            >
              Crear
            </Button>
          </Col>
        </Row>
      )}
      <ConfirmationModal
        show={showModal}
        setShowModal={setShowModal}
        deleteFunction={handleDelete}
        deleteArguments={[]}
      />
    </Form>
  );
};

export default PageForm;
