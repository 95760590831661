import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  // REGISTER_FAIL,
  // REGISTER_SUCCESS,
  SET_MESSAGE
} from "./types";

export const register = () => () => {};

export const login = (userApi, authRequestDto, callback) => (dispatch) => {
  userApi.auth(authRequestDto, (error, data) => {
    if (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      callback && callback(message, null);
    } else {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: {
            id: data.id,
            name: data.name,
            surname: data.surname,
            token: data.token,
            refreshToken: data.refreshToken,
            email: data.email,
            role: data.role,
          },
        },
      });
      dispatch({
        type: SET_MESSAGE,
        payload: "",
      });
    }
  });
};

export const logout = (userApi) => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  
  if(userApi){
    userApi.logout();
  }
};
