import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router";
import { DefaultEditor } from "react-simple-wysiwyg";
import SwaggerContext from "../../SwaggerContext";
import {
  notifyCustomSuccess,
  notifyError,
  notifySuccess,
} from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import CustomToastWithLink from "../../components/ToastWithLink";
import Questions from "./Questions";

const initialState = {
  pagination: {
    page: 0,
    limit: 100,
  },
  page: {
    type: "",
    category_id: "",
    title: "",
    video_link: "",
    audio_link: "",
    body: "",
    interpretation: "",
    questions: [],
  },
};

const PageForm = () => {
  const { id } = useParams();
  const { pageApi, categoryApi } = useContext(SwaggerContext);
  const [data, setData] = useState(null);
  const [categories, setCategories] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;
  const localtion = useLocation();
  const [showModal, setShowModal] = useState(false);

  const loadPage = (id) => {
    pageApi.getPageDetailsById(id, (error, data, response) => {
      if (error || response.statusCode != 200) {
        if (response.statusCode == 404) {
          notifyError(`No se ha encontrado la página.`);
          navigate(`/paginas`);
          return;
        }
        notifyError(`Ha habido un error al cargar la página: ${error}`);
        navigate(`/paginas`);
        return;
      }
      setData(data);
    });
  };

  const loadCategories = (opts) => {
    categoryApi.getCategories(opts, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al cargar las categorías: ${error}`);
        return;
      }
      setCategories(data.categories);
    });
  };

  useEffect(() => {
    let opts = initialState.pagination;
    loadCategories(opts);
    if (currentPath.includes("nueva")) {
      setData(initialState.page);
    } else if (id) {
      loadPage(id);
    }
  }, [localtion]);

  const handleClearForm = () => {
    setData(initialState.page);
  };

  const handleChangePageForm = (e) => {
    let target = e.target;
    let value = target.value;
    setData((previousData) => {
      return { ...previousData, [target.id]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = {};
    if (!data.type) errors.type = true;
    if (!data.category_id) errors.category_id = true;
    if (!data.title) errors.title = true;
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      notifyError("Falta alguno de los campos obligatorios.");
      return;
    }

    if (data.id) {
      savePage();
      return;
    }

    createPage();
  };

  const savePage = () => {
    let pageDto = {
      id: data.id,
      type: data.type,
      category_id: data.category_id,
      title: data.title,
      video_link: data.video_link,
      audio_link: data.audio_link,
      body: data.body,
      interpretation: data.interpretation,
    };

    pageApi.updatePageById(data.id, pageDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al crear las categías: ${error}`);
        return;
      }
      notifySuccess("Página actualizada correctamente.");
    });
    return;
  };

  const createPage = () => {
    let pageInputDto = {
      type: data.type,
      category_id: data.category_id,
      title: data.title,
      video_link: data.video_link,
      audio_link: data.audio_link,
      body: data.body,
      interpretation: data.interpretation,
    };

    pageApi.createPage(pageInputDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al crear las categías: ${error}`);
        return;
      }
      handleClearForm();
      notifyCustomSuccess(
        <CustomToastWithLink
          text="Página guardada correctamente. Click para ver ahora."
          path={`/paginas/editar/${data.id}`}
        />
      );
    });
  };

  const handleDelete = () => {
    pageApi.deletePageById(data.id, (error, data, response) => {
      if (error || response.statusCode != 204) {
        notifyError(`Error al elminar la página: ${error}`);
        return;
      }
      notifySuccess("Página eliminada correctamente");
      navigate(`/paginas`);
    });
  };

  if (null === data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <Fragment>
      <Form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
        <Row>
          <Col>
            <Form.Group controlId="type">
              <Form.Label>
                Tipo<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                isInvalid={errors.type}
                aria-label="Default select example"
                value={data.type}
                name="type"
                onChange={(e) => handleChangePageForm(e)}
              >
                <option>Establecer tipo</option>
                <option value="CAUSA_EMOCION">Causa emoción</option>
                <option value="DESEO_EMOCION">Deso emoción</option>
                <option value="EMOCION_COMPLEJA">Emoción compleja</option>
                <option value="PENSAMIENTO_EMOCION">Pensamiento emoción</option>
                <option value="OTRAS">Otras</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="category_id">
              <Form.Label>
                Categoría<span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                isInvalid={errors.category_id}
                aria-label="Default select example"
                value={data.category_id}
                name="category"
                onChange={(e) => handleChangePageForm(e)}
              >
                <option>Establecer categoría</option>
                {categories
                  ? categories.map((category, i) => {
                      return (
                        <option key={i} value={category.id}>
                          {category.name}
                        </option>
                      );
                    })
                  : null}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="title">
              <Form.Label>
                Título<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                isInvalid={errors.title}
                type="text"
                placeholder="Enter title"
                value={data.title}
                onChange={(e) => handleChangePageForm(e)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group controlId="video_link">
              <Form.Label>Enlace de vídeo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter video link"
                value={data.video_link}
                onChange={(e) => handleChangePageForm(e)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="audio_link">
              <Form.Label>Enlace de audio</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter audio link"
                value={data.audio_link}
                onChange={(e) => handleChangePageForm(e)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Group controlId="bodyForm">
              <Form.Label>Contenido</Form.Label>
              <DefaultEditor
                autoCorrect={false}
                value={data.body}
                onChange={(e) => {
                  e.target.id = "body";
                  setFormChanged(true);
                  handleChangePageForm(e);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Form.Group controlId="interpretation">
              <Form.Label>Explicación</Form.Label>
              <DefaultEditor
                autoCorrect={false}
                value={data.interpretation}
                onChange={(e) => {
                  e.target.id = "interpretation";
                  setFormChanged(true);
                  handleChangePageForm(e);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        {id ? (
          <Row className="d-flex justify-content-end">
            <Col xs="auto" className="d-flex justify-content-end">
              <Button
                variant="danger"
                type="button"
                onClick={() => setShowModal(true)}
              >
                Eliminar
              </Button>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="submit"
                className="mr-2"
                disabled={!formChanged}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        ) : (
          <Row className="d-flex justify-content-end">
            <Col xs="auto" className="d-flex justify-content-end">
              <Button
                className="text-white"
                variant="warning"
                type="button"
                onClick={handleClearForm}
              >
                Limpiar
              </Button>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end">
              <Button
                variant="primary"
                type="submit"
                className="mr-2"
                disabled={!formChanged}
              >
                Crear
              </Button>
            </Col>
          </Row>
        )}
      </Form>
      {data.id ? <Questions data={data} /> : null}
      <ConfirmationModal
        show={showModal}
        setShowModal={setShowModal}
        deleteFunction={handleDelete}
        deleteArguments={[]}
      />
    </Fragment>
  );
};

export default PageForm;
