import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import SwaggerContext from "./SwaggerContext";
import { logout } from "./actions/auth";
import {
  AnswerApi,
  ApiClient,
  CategoryApi,
  PageApi,
  QuestionApi,
  RoleApi,
  UserApi,
} from "./generated";
import Category from "./pages/category";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Page from "./pages/page";
import Profile from "./pages/profile";
import Register from "./pages/register";
import User from "./pages/user";
import PrivateRoute from "./routes/private-route";

const App = () => {
  const [swaggerClient, setSwaggerClient] = useState(null);
  const [client] = useState(new ApiClient());
  const [tokenReady, setTokenReady] = useState(false);

  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const tokenError = (req) => {
    req.on("response", (res) => {
      if (res.status === 401 || res.status === 403) {
        dispatch(logout(swaggerClient.userApi));
      }
    });
  };

  const refreshBearerToken = (userApi) => {
    let refreshTokenRequestDto = { token: state.auth.user.refreshToken };
    userApi.refreshToken(refreshTokenRequestDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        dispatch(logout(swaggerClient ? swaggerClient.userApi : null));
      } else {
        client.authentications["bearerAuth"] = {
          accessToken: data.token,
        };
        client.defaultHeaders["Authorization"] =
          "Bearer " + client.authentications["bearerAuth"].accessToken;
      }
      setTokenReady(true);
    });
  };

  useEffect(() => {
    client.plugins = [tokenError];
    const userApi = new UserApi(client);
    const pageApi = new PageApi(client);
    const categoryApi = new CategoryApi(client);
    const questionApi = new QuestionApi(client);
    const answerApi = new AnswerApi(client);
    const roleApi = new RoleApi(client);
    setSwaggerClient({
      userApi,
      pageApi,
      categoryApi,
      questionApi,
      answerApi,
      roleApi,
    });

    if (state?.auth?.user?.token) {
      refreshBearerToken(userApi);
      const intervalId = setInterval(() => refreshBearerToken(userApi), 240000);
      return () => clearInterval(intervalId);
    } else {
      setTokenReady(true);
    }
  }, [state, client]);

  if (!swaggerClient) {
    return <div>Loading...</div>;
  }

  return (
    <SwaggerContext.Provider value={swaggerClient}>
      <Routes>
        {tokenReady ? (
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/paginas" element={<Page />} />
            <Route path="/paginas/nueva" element={<Page />} />
            <Route path="/paginas/editar/:id" element={<Page />} />
            <Route path="/categorias" element={<Category />} />
            <Route path="/categorias/nueva" element={<Category />} />
            <Route path="/categorias/editar/:id" element={<Category />} />
            <Route path="/usuarios" element={<User />} />
            <Route path="/usuarios/nuevo" element={<User />} />
            <Route path="/usuarios/editar/:id" element={<User />} />
            <Route path="/perfil" element={<Profile />} />
            <Route path="*" element={<div>Not found</div>} />
          </Route>
        ) : null}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </SwaggerContext.Provider>
  );
};

export default App;
