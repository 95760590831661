import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import List from "./List";
import Form from "./Form";

const User = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const routes = {
    "/usuarios": (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Listado de Usuarios
        </h1>
        <List />
      </Fragment>
    ),
    "/usuarios/nuevo": (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Nuevo Usuario
        </h1>
        <Form />
      </Fragment>
    ),
    [`/usuarios/editar/${id}`]: (
      <Fragment>
        <h1 className="d-flex justify-content-center align-items-center">
          Editar Usuario
        </h1>
        <Form />
      </Fragment>
    ),
  };

  return routes[pathname];
};

export default User;
