import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomToastWithLink = ({ text, path }) => {
  const navigate = useNavigate();

  return <div onClick={() => navigate(path)}>{text}</div>;
};

CustomToastWithLink.propTypes = {
  text: PropTypes.string,
  path: PropTypes.string,
};

export default CustomToastWithLink;
