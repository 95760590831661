import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import PaginationComponent from "../../components/pagination";
import SwaggerContext from "../../SwaggerContext";

const initialState = {
  pagination: {
    page: 0,
    limit: 10,
  },
};

const loadUsers = (userApi, setData, opts) => {
  userApi.getUsers(opts, (error, data, response) => {
    if (error || response.statusCode != 200) {
      notifyError(
        "Ha habido un error al cargar la lista de usuarios. " + error
      );
      return;
    }
    setData(data);
  });
};

const removeUser = (userApi, setData, id, pageNumber) => {
  userApi.deleteUserById(id, (error, data, response) => {
    if (error || response.statusCode != 204) {
      notifyError("Ha habido un error al borrar la usuario. " + error);
      return;
    }
    notifySuccess("Usuario eliminado correctamente.");
    let opts = initialState.pagination;
    opts.page = pageNumber;
    loadUsers(userApi, setData, opts);
  });
};

const UsersTable = React.memo(({ userApi, users, setData, pageNumber }) => {
  const userHeaders = ["Nombre", "Apellidos", "Email", "Nombre de usuario"];
  const navigate = useNavigate();
  const [deleteArguments, setDeleteArguments] = useState();
  const [showModal, setShowModal] = useState(false);
  const auth = useSelector((state) => state.auth);

  return (
    <Table striped bordered hover variant="dark mt-1 text-center">
      <thead>
        <tr>
          <th>#</th>
          {userHeaders.map((header, i) => {
            return <th key={i}>{header}</th>;
          })}
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, i) => {
          return (
            <tr
              style={{ cursor: "pointer" }}
              key={i}
              onClick={() => {
                if (user.id === auth.user.id) {
                  navigate(`/perfil`);
                  return;
                }
                navigate(`/usuarios/editar/${user.id}`);
              }}
            >
              <td>{i + 1}</td>
              <td>{user.name}</td>
              <td>{user.surname}</td>
              <td>{user.email}</td>
              <td>{user.username}</td>
              <td>
                <Button
                  disabled={user.id === auth.user.id}
                  variant="danger"
                  onClick={() => {
                    setDeleteArguments([userApi, setData, user.id, pageNumber]);
                    setShowModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
      <ConfirmationModal
        show={showModal}
        setShowModal={setShowModal}
        deleteFunction={removeUser}
        deleteArguments={deleteArguments}
      />
    </Table>
  );
});

UsersTable.displayName = "UsersTable";
UsersTable.propTypes = {
  users: PropTypes.array,
  setData: PropTypes.func,
  userApi: PropTypes.object,
  pageNumber: PropTypes.number,
};

const List = () => {
  const { userApi } = useContext(SwaggerContext);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  let [opts, setOpts] = useState(initialState.pagination);
  useEffect(() => {
    loadUsers(userApi, setData, opts);
  }, [opts]);

  if (null === data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Fragment>
      <Button variant="success" onClick={() => navigate("/usuarios/nuevo")}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <UsersTable
        users={data.users}
        setData={setData}
        userApi={userApi}
        pageNumber={data.page}
      />
      <div className="d-flex justify-content-center align-items-center">
        <PaginationComponent pages={data.totalPages} setOpts={setOpts} />
      </div>
    </Fragment>
  );
};

export default List;
