import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import PaginationComponent from "../../components/pagination";
import SwaggerContext from "../../SwaggerContext";

const initialState = {
  pagination: {
    page: 0,
    limit: 10,
  },
};

const loadCategories = (categoryApi, setData, opts) => {
  categoryApi.getCategories(opts, (error, data, response) => {
    if (error || response.statusCode != 200) {
      notifyError(
        "Ha habido un error al cargar la lista de categorías. " + error
      );
      return;
    }
    setData(data);
  });
};

const removeCategory = (categoryApi, setData, id, pageNumber) => {
  categoryApi.deleteCategoryById(id, (error, data, response) => {
    if (error || response.statusCode != 204) {
      notifyError("Ha habido un error al borrar la categoría. " + error);
      return;
    }
    notifySuccess("Categoría eliminada correctamente.");
    let opts = initialState.pagination;
    opts.page = pageNumber;
    loadCategories(categoryApi, setData, opts);
  });
};

const CategoriesTable = React.memo(
  ({ categoryApi, categories, setData, pageNumber }) => {
    const categoryHeaders = ["Nombre", "Grupo"];
    const navigate = useNavigate();
    const [deleteArguments, setDeleteArguments] = useState();
    const [showModal, setShowModal] = useState(false);
    return (
      <Table striped bordered hover variant="dark mt-1 text-center">
        <thead>
          <tr>
            <th>#</th>
            {categoryHeaders.map((header, i) => {
              return <th key={i}>{header}</th>;
            })}
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, i) => {
            return (
              <tr
                key={i}
                onClick={() => navigate(`/categorias/editar/${category.id}`)}
                style={{ cursor: "pointer" }}
              >
                <td>{i + 1}</td>
                <td>{category.name}</td>
                <td>{category.group}</td>
                <td>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setDeleteArguments([
                        categoryApi,
                        setData,
                        category.id,
                        pageNumber,
                      ]);
                      setShowModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
        <ConfirmationModal
          show={showModal}
          setShowModal={setShowModal}
          deleteFunction={removeCategory}
          deleteArguments={deleteArguments}
        />
      </Table>
    );
  }
);

CategoriesTable.displayName = "CategoriesTable";
CategoriesTable.propTypes = {
  categories: PropTypes.array,
  setData: PropTypes.func,
  categoryApi: PropTypes.object,
  pageNumber: PropTypes.number,
};

const List = () => {
  const { categoryApi } = useContext(SwaggerContext);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  let [opts, setOpts] = useState(initialState.pagination);
  useEffect(() => {
    loadCategories(categoryApi, setData, opts);
  }, [opts]);

  if (null === data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Fragment>
      <Button variant="success" onClick={() => navigate("/categorias/nueva")}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <CategoriesTable
        categories={data.categories}
        setData={setData}
        categoryApi={categoryApi}
        pageNumber={data.page}
      />
      <div className="d-flex justify-content-center align-items-center">
        <PaginationComponent pages={data.totalPages} setOpts={setOpts} />
      </div>
    </Fragment>
  );
};

export default List;
