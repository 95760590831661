import {
  faPencil,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, ButtonGroup, Col, Form, Row } from "react-bootstrap";
import { notifyError, notifySuccess } from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import SwaggerContext from "../../SwaggerContext";

const Questions = ({ data }) => {
  const [questions, setQuestions] = useState(new Array());
  const [errors, setErrors] = useState([]);
  const { questionApi, answerApi } = useContext(SwaggerContext);
  const [items, setItems] = useState([]);
  const myRefs = useRef({});
  const [deleteArguments, setDeleteArguments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteFunction, setDeleteFunction] = useState();

  useEffect(() => {
    if (Object.keys(myRefs.current).length > 0) {
      const keys = Object.keys(myRefs.current);
      const lastKey = keys[keys.length - 1];
      myRefs.current[lastKey].scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [items]);

  Questions.propTypes = {
    data: PropTypes.object,
  };

  useEffect(() => {
    initializeQuestions();
  }, []);

  const initializeQuestions = () => {
    if (!data.questions) return;
    let questions = data.questions.map((question) => {
      let answers = question.answers
        ? question.answers.map((answer) => {
            return {
              is_correct: answer.is_correct,
              content: answer.content,
              disabled: true,
              id: answer.id,
            };
          })
        : [];
      return {
        difficulty: question.difficulty,
        content: question.content,
        interpretation: question.interpretation,
        answers: answers,
        id: question.id,
        disabled: true,
      };
    });
    setQuestions(questions);
  };

  const handleAddQuestion = () => {
    const newQuestion = {
      content: "",
      interpretation: "",
      dificulty: "",
      answers: new Array(),
    };
    setQuestions([...questions, newQuestion]);
    let i = questions.length ? questions.length : 1;
    handleAddItem(`question-${i - 1}`);
  };

  const handleQuestionChange = (e, i) => {
    let target = e.target;
    let value = target.value;
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[i][target.name] = value;
      return newQuestions;
    });
  };

  const handleAnswerChange = (e, i, j) => {
    let target = e.target;
    let value = target.value;
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      newQuestions[i].answers[j][target.name] = value;
      return newQuestions;
    });
  };

  const handleEnable = (i, j) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      if (j !== null && j !== undefined) {
        newQuestions[i].answers[j]["disabled"] = false;
      } else {
        newQuestions[i]["disabled"] = false;
      }
      return newQuestions;
    });
  };

  const handleAddAnswer = (i) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      let newAnswer = {
        is_correct: "",
        content: "",
      };
      let previousAnswers = newQuestions[i].answers;
      newQuestions[i].answers = [...previousAnswers, newAnswer];
      return newQuestions;
    });
    let j = questions[i].answers.length ? questions[i].answers.length : 1;
    handleAddItem(`question-${i}-answer-${j - 1}`);
  };

  const handleRemoveQuestion = (i, id) => {
    if (id) {
      questionApi.deleteQuestionById(id, (error, data, response) => {
        if (error || response.statusCode != 204) {
          notifyError(`Error al elminar la pregunta: ${error}`);
          return;
        }
        notifySuccess("Pregunta eliminada correctamente");
      });
    }
    const newQuestions = [...questions];
    newQuestions.splice(i, 1);
    removeErrors(i);
    setQuestions(newQuestions);
  };

  const handleRemoveAnswer = (i, j, id) => {
    if (id) {
      answerApi.deleteAnswerById(id, (error, data, response) => {
        if (error || response.statusCode != 204) {
          notifyError(`Error al elminar la respuesta: ${error}`);
          return;
        }
        notifySuccess("Respuesta eliminada correctamente");
      });
    }
    const newQuestions = [...questions];
    newQuestions[i].answers.splice(j, 1);
    setQuestions(newQuestions);
    removeErrors(i, j);
  };

  const removeErrors = (i, j) => {
    if (j) {
      if (errors[i] && errors[i].answers && errors[i].answers[j]) {
        const newErrors = [...errors];
        newErrors[i].answers.splice(j, 1);
        setErrors(newErrors);
      }
    }
    if (errors[i]) {
      const newErrors = [...errors];
      newErrors.splice(i, 1);
      setErrors(newErrors);
    }
  };

  const handleCreateOrUpdateQuestion = (i, id) => {
    let newErrors = [...errors];
    newErrors[i] = {};
    if (!questions[i].content) newErrors[i].content = true;
    if (!questions[i].difficulty) newErrors[i].difficulty = true;
    if (Object.keys(newErrors[i]).length > 0) {
      setErrors(newErrors);
      notifyError("Falta alguno de los campos obligatorios.");
      return;
    }
    removeErrors(i);
    if (id) {
      saveQuestion(i, id);
      return;
    }

    createQuestion(i);
  };

  const handleCreateOrUpdateAnswer = (i, j, question_id, id) => {
    let newErrors = [...errors];
    newErrors[i] = newErrors[i] || {};
    newErrors[i].answers = newErrors[i].answers || {};
    newErrors[i].answers[j] = {};
    if (!questions[i].answers[j].content)
      newErrors[i].answers[j].content = true;
    if (!questions[i].answers[j].is_correct)
      newErrors[i].answers[j].is_correct = true;
    if (Object.keys(newErrors[i].answers[j]).length > 0) {
      setErrors(newErrors);
      notifyError("Falta alguno de los campos obligatorios.");
      return;
    }
    removeErrors(i, j);
    if (id) {
      saveAnswer(i, j, question_id, id);
      return;
    }

    createAnswer(i, j, question_id);
  };

  const createQuestion = (i) => {
    let questionInputDto = {
      page_id: data.id,
      content: questions[i].content,
      interpretation: questions[i].interpretation,
      difficulty: questions[i].difficulty,
    };
    questionApi.createQuestion(questionInputDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al crear la pregunta: ${error}`);
        return;
      }
      const newQuestions = [...questions];
      newQuestions[i].id = data.id;
      newQuestions[i].disabled = true;
      setQuestions(newQuestions);
      notifySuccess("Pregunta creada correctamente.");
    });
  };

  const createAnswer = (i, j, question_id) => {
    let answerInputDto = {
      question_id: question_id,
      content: questions[i].answers[j].content,
      is_correct: questions[i].answers[j].is_correct,
    };

    answerApi.createAnswer(answerInputDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al crear la respuesta: ${error}`);
        return;
      }
      const newQuestions = [...questions];
      newQuestions[i].answers[j].id = data.id;
      newQuestions[i].answers[j].disabled = true;
      setQuestions(newQuestions);
      notifySuccess("Respuesta creada correctamente.");
    });
  };

  const saveQuestion = (i, id) => {
    let questionDto = {
      page_id: data.id,
      content: questions[i].content,
      interpretation: questions[i].interpretation,
      difficulty: questions[i].difficulty,
    };
    questionApi.updateQuestionById(id, questionDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al guardar la pregunta: ${error}`);
        return;
      }
      const newQuestions = [...questions];
      newQuestions[i].disabled = true;
      setQuestions(newQuestions);
      notifySuccess("Pregunta guardada correctamente.");
    });
  };

  const saveAnswer = (i, j, question_id, id) => {
    let answerDto = {
      question_id: question_id,
      content: questions[i].answers[j].content,
      is_correct: questions[i].answers[j].is_correct,
    };
    answerApi.updateAnswerById(id, answerDto, (error, data, response) => {
      if (error || response.statusCode != 200) {
        notifyError(`Ha habido un error al guardar la respuesta: ${error}`);
        return;
      }
      const newQuestions = [...questions];
      newQuestions[i].answers[j].disabled = true;
      setQuestions(newQuestions);
      notifySuccess("Respuesta guardada correctamente.");
    });
  };

  const handleAddItem = (item) => {
    setItems([...items, item]);
  };

  return (
    <Form>
      <Row className="mt-4 mb-2">
        <Col>
          <h3>Preguntas</h3>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Button variant="success" onClick={handleAddQuestion}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>
      {questions.map((question, i) => {
        return (
          <Form.Group key={i} className="border mb-4" controlId="questionary">
            <Row
              ref={(el) => {
                myRefs.current[`question-${i}`] = el;
              }}
            >
              <Col lg="10">
                <Form.Group controlId={"questionContent" + i} className="p-1">
                  <Form.Label>
                    Pregunta<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    isInvalid={errors[i] && errors[i].content}
                    disabled={question.disabled}
                    as="textarea"
                    name="content"
                    placeholder="Escribe una pregunta"
                    value={question.content}
                    onChange={(e) => handleQuestionChange(e, i)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs lg="2">
                <Form.Group
                  controlId={"questionDifficulty" + i}
                  className="p-1"
                >
                  <Form.Label>
                    Dificultad<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    isInvalid={errors[i] && errors[i].difficulty}
                    disabled={question.disabled}
                    aria-label="Default select example"
                    value={question.difficulty}
                    name="difficulty"
                    onChange={(e) => handleQuestionChange(e, i)}
                  >
                    <option>Establecer dificultad</option>
                    <option value="1">Fácil</option>
                    <option value="2">Media</option>
                    <option value="3">Difícil</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col lg="10">
                <Form.Group controlId={"questionInterpretation" + i} className="p-1">
                  <Form.Label>
                    Explicación
                  </Form.Label>
                  <Form.Control
                    isInvalid={errors[i] && errors[i].interpretation}
                    disabled={question.disabled}
                    as="textarea"
                    name="interpretation"
                    placeholder="Escribe una explicación"
                    value={question.interpretation}
                    onChange={(e) => handleQuestionChange(e, i)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col
                xs
                lg="2 d-flex justify-content-center align-items-center mt-3"
              >
                <ButtonGroup aria-label="Basic example">
                  {question.id && question.disabled ? (
                    <Button
                      variant="warning"
                      id="disabled"
                      onClick={() => {
                        handleEnable(i);
                      }}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Button>
                  ) : (
                    <Button
                      variant="success"
                      onClick={() => {
                        handleCreateOrUpdateQuestion(i, question.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  )}
                  <Button
                    variant="danger"
                    onClick={() => {
                      setDeleteArguments([i, question.id]);
                      setDeleteFunction(() => handleRemoveQuestion);
                      setShowModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            {question.id ? (
              <Fragment>
                <Row className="p-1">
                  <Col>
                    <h5>Respuestas</h5>
                  </Col>
                </Row>
                <Row className="mb-2 p-1">
                  <Col>
                    <Button
                      variant="success"
                      onClick={() => handleAddAnswer(i)}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </Col>
                </Row>
                {question.answers.map((answer, j) => {
                  return (
                    <Row
                      key={i + j}
                      ref={(el) => {
                        myRefs.current[`question-${i}-answer-${j}`] = el;
                      }}
                    >
                      <Col>
                        <Form.Group
                          controlId={"answerContent" + i}
                          className="p-1"
                        >
                          <Form.Label>Respuesta</Form.Label>
                          <Form.Control
                            isInvalid={
                              errors[i] &&
                              errors[i].answers &&
                              errors[i].answers[j] &&
                              errors[i].answers[j].content
                            }
                            disabled={answer.disabled}
                            name="content"
                            as="textarea"
                            placeholder="Escribe una respuesta"
                            value={answer.content}
                            onChange={(e) => handleAnswerChange(e, i, j)}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs lg="2">
                        <Form.Group
                          controlId={"answerVeracity" + i}
                          className="p-1"
                        >
                          <Form.Label>Veracidad</Form.Label>
                          <Form.Select
                            isInvalid={
                              errors[i] &&
                              errors[i].answers &&
                              errors[i].answers[j] &&
                              errors[i].answers[j].is_correct
                            }
                            disabled={answer.disabled}
                            name="is_correct"
                            aria-label="Default select example"
                            value={answer.is_correct}
                            onChange={(e) => handleAnswerChange(e, i, j)}
                          >
                            <option>Establecer veracidad</option>
                            <option value="true">Verdadera</option>
                            <option value="false">Falsa</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col
                        xs
                        lg="2 d-flex justify-content-center align-items-center mt-3"
                      >
                        <ButtonGroup aria-label="Basic example">
                          {answer.id && answer.disabled ? (
                            <Button
                              variant="warning"
                              id="disabled"
                              onClick={() => {
                                handleEnable(i, j);
                              }}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </Button>
                          ) : (
                            <Button
                              variant="success"
                              onClick={() => {
                                handleCreateOrUpdateAnswer(
                                  i,
                                  j,
                                  question.id,
                                  answer.id
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faSave} />
                            </Button>
                          )}
                          <Button
                            variant="danger"
                            onClick={() => {
                              setDeleteArguments([i, j, answer.id]);
                              setDeleteFunction(() => handleRemoveAnswer);
                              setShowModal(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>
                  );
                })}
              </Fragment>
            ) : null}
          </Form.Group>
        );
      })}
      <ConfirmationModal
        show={showModal}
        setShowModal={setShowModal}
        deleteFunction={deleteFunction}
        deleteArguments={deleteArguments}
      />
    </Form>
  );
};

export default Questions;
