import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../actions/notifications";
import ConfirmationModal from "../../components/ConfirmationModal";
import PaginationComponent from "../../components/pagination";
import SwaggerContext from "../../SwaggerContext";

const initialState = {
  pagination: {
    page: 0,
    limit: 10,
  },
};

const loadPages = (pageApi, setData, opts) => {
  pageApi.getPages(opts, (error, data, response) => {
    if (error || response.statusCode != 200) {
      notifyError("Ha habido un error al cargar la lista de páginas. " + error);
      return;
    }
    setData(data);
  });
};

const removePage = (pageApi, setData, id, pageNumber) => {
  pageApi.deletePageById(id, (error, data, response) => {
    if (error || response.statusCode != 204) {
      notifyError("Ha habido un error al borrar la página. " + error);
      return;
    }
    notifySuccess("Página eliminada correctamente.");
    let opts = initialState.pagination;
    opts.page = pageNumber;
    loadPages(pageApi, setData, opts);
  });
};

const PagesTable = React.memo(({ pageApi, pages, setData, pageNumber }) => {
  const pageHeaders = ["Tipo", "Categoría", "Título"];
  const [deleteArguments, setDeleteArguments] = useState();
  const [showModal, setShowModal] = useState();
  const navigate = useNavigate();
  return (
    <Table striped bordered hover variant="dark mt-1 text-center">
      <thead>
        <tr>
          <th>#</th>
          {pageHeaders.map((header, i) => {
            return <th key={i}>{header}</th>;
          })}
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {pages.map((page, i) => {
          return (
            <tr
              key={i}
              onClick={() => navigate(`/paginas/editar/${page.id}`)}
              style={{ cursor: "pointer" }}
            >
              <td>{i + 1}</td>
              <td>{page.type}</td>
              <td>{page.category_name}</td>
              <td>{page.title}</td>
              <td>
                <Button
                  variant="danger"
                  onClick={() => {
                    setShowModal(true);
                    setDeleteArguments([pageApi, setData, page.id, pageNumber]);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
      <ConfirmationModal
        show={showModal}
        setShowModal={setShowModal}
        deleteFunction={removePage}
        deleteArguments={deleteArguments}
      />
    </Table>
  );
});

PagesTable.displayName = "PagesTable";
PagesTable.propTypes = {
  pages: PropTypes.array,
  setData: PropTypes.func,
  pageApi: PropTypes.object,
  pageNumber: PropTypes.number,
};

const List = () => {
  const { pageApi } = useContext(SwaggerContext);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  let [opts, setOpts] = useState(initialState.pagination);
  useEffect(() => {
    loadPages(pageApi, setData, opts);
  }, [opts]);

  if (null === data) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "calc(100vh - 200px)" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Fragment>
      <Button variant="success" onClick={() => navigate("/paginas/nueva")}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <PagesTable
        pages={data.pages}
        setData={setData}
        pageApi={pageApi}
        pageNumber={data.page}
      />
      <div className="d-flex justify-content-center align-items-center">
        <PaginationComponent pages={data.totalPages} setOpts={setOpts} />
      </div>
    </Fragment>
  );
};

export default List;
