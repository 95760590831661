import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const ConfirmationModal = ({
  show,
  setShowModal,
  deleteFunction,
  deleteArguments,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-50w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          ¿Está seguro de que desea eliminar el elemento?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Button
          className="mx-2"
          variant="success"
          onClick={() => {
            setShowModal(false);
            deleteFunction(...deleteArguments);
          }}
        >
          Aceptar
        </Button>{" "}
        <Button variant="danger" onClick={() => setShowModal(false)}>
          Cancelar
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  setShowModal: PropTypes.func,
  deleteFunction: PropTypes.func,
  deleteArguments: PropTypes.array,
};

export default ConfirmationModal;
