import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import SwaggerContext from "../../SwaggerContext";

const TopBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userApi } = useContext(SwaggerContext);


  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark">
      <div className="container-fluid">
        <div className="d-flex">
          <div className="navbar-nav ml-auto">
            <Link to={"/"} className="navbar-brand">
              Inicio
            </Link>
          </div>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/paginas"} className="nav-link">
                Páginas
              </Link>
            </li>
          </div>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/categorias"} className="nav-link">
                Categorías
              </Link>
            </li>
          </div>
          <div className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link to={"/usuarios"} className="nav-link">
                Usuarios
              </Link>
            </li>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="navbar-nav ml-auto">
            <DropdownButton
              variant="secondary"
              id="dropdown-basic-button"
              title={
                <span>
                  <FontAwesomeIcon icon={faUser} />
                </span>
              }
            >
              <Dropdown.Item onClick={() => navigate("/perfil")}>
                Perfil
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  dispatch(logout(userApi));
                  navigate("/login")
                }}
              >
                Cerrar Sesión
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
