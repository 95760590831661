import React from "react";
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate";

const PaginationComponent = React.memo(({ pages, setOpts }) => {
  return (
    <>
      <ReactPaginate
        previousLabel="< Anterior"
        nextLabel="Siguiente >"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={({ selected }) => {
          setOpts({ page: selected, limit: 10 });
        }}
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
});

PaginationComponent.displayName = "PaginationComponent";

PaginationComponent.propTypes = {
  pages: PropTypes.number,
  setOpts: PropTypes.func,
};

export default PaginationComponent;
